import { graphql, PageProps } from 'gatsby'
import React from 'react'
import CommonH2 from '../../components/atoms/commonH2'
import SEO from '../../components/atoms/seo'
import { ITopicPath } from '../../components/atoms/topicPath'
import CategoryCard from '../../components/molecules/categoryCard'
import Layout from '../../components/organisms/layout'
import { CategoryKind } from '../../types/CategoryKind'
import ManualBtn from '../../components/molecules/manualBtn'
import useRouteParam from '../../hooks/useRouteParam'

type IProps = PageProps<GatsbyTypes.HelpIndexPageQuery>

/**
 * はじめてガイドページ
 * @constructor
 */
const FirstPage = ({ data, location }: IProps) => {
  // GraphQLで取得したデータを保持
  const items = data.awsGraphQL.listHelpCategories?.items || []

  const paths: ITopicPath[] = [
    { title: 'TOP', link: useRouteParam('/help') },
    { title: 'はじめてガイド' },
  ]
  return (
    <Layout location={location} paths={paths} categoryKind={CategoryKind.FIRST}>
      <SEO title="はじめてガイド" />
      <CommonH2 label="はじめてガイド" />
      <p className="txt-center mt-020 mb-020">
        キンクラを初めてご利用いただく際に読んでいただきたい、基本操作をご案内します。
      </p>
      {[...items]
        .sort((a, b) => (a?.sortNo || 0) - (b?.sortNo || 0))
        .filter((item) => !item?._deleted)
        .map((item) => {
          const helpCategoryId = item?.helpCategoryId || ''
          const slug = item?.slug || ''
          const title = item?.title || ''
          return (
            <CategoryCard
              key={`category-card-${helpCategoryId}`}
              helpCategoryId={helpCategoryId}
              categoryTitle={title}
              slug={slug}
              limit={10}
              visibleAllLink
            />
          )
        })}
      <div className="manual-button">
        <ManualBtn />
      </div>
    </Layout>
  )
}

export default FirstPage

export const pageQuery = graphql`
  query items {
    awsGraphQL {
      listHelpCategories(filter: { categoryKind: { eq: 1 } }) {
        items {
          helpCategoryId
          sortNo
          title
          slug
          categoryKind
          _deleted
        }
      }
    }
    site {
      siteMetadata {
        title
      }
    }
  }
`
